var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"90135dd624b76c2ebe4fb212c5509da48cc46710"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

import { getPublicConfig } from "./src/helpers/getPublicConfig";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const { isLocal, vercelEnv } = getPublicConfig();

if (!isLocal) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://55a04f30a08d4ddc9aa0130cd47eabd6@o504859.ingest.sentry.io/5659344",
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    tracesSampleRate: vercelEnv === "production" ? 0.2 : 0.5,
    environment: vercelEnv,
  });
}
